import React, { useState, useCallback } from "react";
import styled from "styled-components";

// import { render } from "react-dom"
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import mediaQuery from "src/assets/styles/mediaQuery";

const GalleryMobile = styled.div`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
  }
`;

const GalleryWrapper = styled.div`
  display: block;
  z-index: 99999;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

//
const GalleryModule = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  let col = 3;
  // let w = window.innerWidth;

  // if (w < size.mobile) {
  //   col = 2;
  // }

  return (
    <>
      <GalleryWrapper>
        <Gallery
          photos={props.photos}
          onClick={openLightbox}
          direction={"column"}
          columns={col}
          margin={8}
        />
      </GalleryWrapper>

      <GalleryMobile>
        <Gallery
          photos={props.photos}
          onClick={openLightbox}
          direction={"column"}
          columns={2}
          margin={8}
        />
      </GalleryMobile>

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal className="modal-image" onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={props.photos.map((x) => ({
                ...x,
                srcSet: x.srcSet,
                width: x.width,
                height: x.height,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

export default GalleryModule;
