import * as React from "react";
import styled from "styled-components";
import SEO from "src/components/SEO.js"

import Wrapper from "src/components/global/Wrapper.js";
import colors from "src/assets/styles/colors.js";
import BodyText from "src/components/typography/BodyText.js";
import Title from "src/components/typography/Title.js";

import mediaQuery from "src/assets/styles/mediaQuery";

import Cta from "src/components/global/Cta.js";
import ProjectGallery from "src/components/Realizations/ProjectGallery.js";
const Header = styled.header`
  background-color: ${colors.lightGrey};
  padding-top: 250px;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    height: 180px;
    background-color: ${colors.lightGrey};
    left: 0;
    transform: translateY(100%);
    width: 100%;
    z-index: -100;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 108px;

    ::after {
      height: 55vw;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-top: 17.333vw;

    ::after {
      height: 12.479vw;
      bottom: 1px;
    }
  }
`;

const HeaderWrapper = styled(Wrapper)`
  display: flex;
  padding-bottom: 140px;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding-bottom: 45px;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-bottom: 9.706vw;
  }
`;

const MainInformation = styled.div`
  width: 41.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const HeaderSmallText = styled(BodyText)`
  color: ${colors.mainColor};
  position: relative;
  top: 15px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 20%;
    top: 0px;
    margin-top: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    top: 1.039vw;
  }
`;

const StyledTitle = styled(Title)`
  width: 80%;
  max-width: 350px;
  margin-bottom: 25px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    max-width: 24.266vw;
    margin-bottom: 1.733vw;
  }
`;
const HeaderDescription = styled(BodyText)`
  margin-left: 20%;
`;

const NavRealizationWrapper = styled.nav`
  display: flex;
  margin-bottom: 50px;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 3.466vw;
  }
`;

const FilterTitle = styled.h3`
  font-size: 10px;
  color: ${colors.mainColor};
  font-weight: 300;
  width: 8.33%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: 0.693vw;
  }
`;

const NavRealization = styled.nav`
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    overflow-x: scroll;
    padding-bottom: 2px;
  }
`;

const NavLink = styled(BodyText)`
  margin-right: 50px;
  cursor: pointer;
  position: relative;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  white-space: nowrap;
  :hover {
    ::after {
      width: 20%;
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  ::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: ${colors.darkGrey};
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &.active {
    ::after {
      width: 100%;
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-right: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-right: 3.466vw;
  }
  ::after {
    bottom: -0.138vw;

    height: 0.069vw;
  }
`;

class RealizacjePage extends React.Component {
  state = {
    type: 0,
  };

  changeType = (n) => {
    this.setState({
      type: n,
    });
  };
  render() {
    const photos = [];

    this.props.data.allStrapiTypeOfRealizations.edges.map((document, index) => {
      photos.push([]);

      this.props.data.allStrapiTypeOfRealizations.edges[
        index
      ].node.realizations.map((document) => {
        const newImg = {
          width: document.Image.childImageSharp.original.width,
          height: document.Image.childImageSharp.original.height,
          src: document.Image.childImageSharp.fluid.src,
          srcSet: document.Image.childImageSharp.fluid.srcSet,
        };
        photos[index].push(newImg);
      });
    });

    return (
      <>
        <SEO title={this.props.data.strapiRealizationsPage.SEO.Title}
        description={this.props.data.strapiRealizationsPage.SEO.Description}/>
        <Header>
          <HeaderWrapper>
            <MainInformation>
              <StyledTitle
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {this.props.data.strapiRealizationsPage.Title}
              </StyledTitle>
              <HeaderDescription
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {this.props.data.strapiRealizationsPage.Description}
              </HeaderDescription>
            </MainInformation>
            <HeaderSmallText
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {this.props.data.strapiRealizationsPage.Subtitle}
            </HeaderSmallText>
          </HeaderWrapper>
        </Header>
        <Wrapper>
          <NavRealizationWrapper>
            <FilterTitle>Filtruj:</FilterTitle>
            <NavRealization>
              {this.props.data.allStrapiTypeOfRealizations.edges.map(
                (document, index) => (
                  <NavLink
                    className={this.state.type === index ? "active" : ""}
                    onClick={() => this.changeType(index)}
                  >
                    {document.node.Name}
                  </NavLink>
                )
              )}
            </NavRealization>
          </NavRealizationWrapper>
          <ProjectGallery photos={photos[this.state.type]} />
        </Wrapper>

        <Cta />
      </>
    );
  }
}

export const RealizacjeQuery = graphql`
  query RealizacjeQuery {
    strapiRealizationsPage {
      Title
      Subtitle
      Description
      SEO {
        Description
        Title
      }
    }
    allStrapiTypeOfRealizations {
      edges {
        node {
          id
          Name
          realizations {
            type_of_realization

            Image {
              childImageSharp {
                original {
                  height
                  width
                }
                fluid(maxWidth: 1600) {
                  srcSet
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default RealizacjePage;
